import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const Badges = () => {
  return (
    <>
      <Helmet>
        <script
          async="true"
          src="https://b.sf-syn.com/badge_js?sf_id=3855075&variant_id=sf"
        ></script>
        <script
          async="true"
          src="https://b.sf-syn.com/badge_js?sf_id=3855075&variant_id=sd"
        ></script>
        <script
          type="text/javascript"
          src="https://assets.goodfirms.co/assets/js/widget.min.js"
        ></script>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>

      {/* SourceForge Badges */}
      <div className="badge-link">
        <div
          className="sf-root"
          data-id="3855075"
          data-badge="light-default"
          data-variant-id="sf"
          style={{ width: "70px" }}
        >
          <a
            href="https://sourceforge.net/software/product/Panacea-Blood-Bank/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Panacea Reviews
          </a>
        </div>
      </div>

      <div className="badge-link">
        <div
          className="sf-root"
          data-id="3855075"
          data-badge="users-love-us-new-white"
          data-variant-id="sd"
          style={{ width: "70px" }}
        >
          <a
            href="https://slashdot.org/software/p/Panacea-Blood-Bank/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Panacea Reviews
          </a>
        </div>
      </div>

      {/* Top Firms Badges */}
      <div className="badge-link">
        <div>
          <a
            href="https://topfirms.co/companies/web-development/australia"
            target="_blank"
          >
            <img
              src="https://topfirms.co/wp-content/uploads/2019/10/top-web-development-company-badge-square.jpg"
              alt="eCommerce Development Companies"
            />
          </a>
        </div>
      </div>

      <div className="badge-link">
        <div>
          <a
            href="https://topfirms.co/companies/software-development/usa"
            target="_blank"
          >
            <img
              src="https://topfirms.co/wp-content/uploads/2020/03/software-development-companies-round-badge.png"
              alt="eCommerce Development Companies"
            />
          </a>
        </div>
      </div>

      {/* GoodFirms Badge */}
      <div className="badge-link">
        <div
          className="goodfirm-widget"
          data-widget-type="goodfirms-widget-t8"
          data-widget-pattern="poweredby-star"
          data-height="100"
          data-company-id="141674"
        ></div>
      </div>

      {/* TrustPilot Badge */}
      <div className="badge-link">
        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="652761e1455122d8766ea49a"
          data-style-height="52px"
          data-style-width="100%"
        >
          <a
            href="https://www.trustpilot.com/review/zaavia.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </div>
    </>
  )
}

export default Badges
